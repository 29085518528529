<template>
    <div class="o-layout o-layout--medium">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <recess-button :title="BUTTON_TEXT.goBack" icon="back" variant="variant4" class="qa-go-back-button"
                        @click="$router.go(-1)" />

                    <title-text :title="PROVIDER.Order.OrderDetails.Title" class="qa-order-title" />
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <!-- Overlay block -->
                    <recess-card v-if="order" variant="variant1">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12">
                                    <h2 class="qa-order-summary-number">
                                        {{ PROVIDER.Order.OrderDetails.OrderNumber }}
                                        {{ order.orderNumber }}
                                    </h2>
                                    <p>
                                        <span class="qa-order-student-full-name">
                                            {{ getStudentFullName }}
                                        </span>
                                        -
                                        <span class="qa-order-summary-name">{{ order.name }}</span>
                                    </p>
                                </div>

                                <div class="col-12">
                                    <div class="d-flex flex-wrap justify-content-between">
                                        <div class="d-flex flex-column">
                                            <p class="mb-0 qa-order-type">
                                                <span class="u-text-bold mr-1">{{ PROVIDER.Order.OrderDetails.Type
                                                    }}:</span>
                                                {{ showOrderTypeDisplayValue(order.orderType) }}
                                            </p>
                                            <p class="qa-order-status">
                                                <span class="u-text-bold mr-1">{{ PROVIDER.Order.OrderDetails.Status
                                                    }}</span>
                                                {{ showOrderStatusDisplayValue(order.status) }}
                                            </p>

                                        </div>
                                        <div>
                                            <recess-button v-if="showProviderApprovalButtons(order.status)"
                                                variant="tertiary" :title="BUTTON_TEXT.acceptOrder"
                                                :disabled="disableProviderApprovalButtonsForAdmin"
                                                class="mr-3 qa-order-details-accept-button"
                                                @click.native.prevent="acceptOrderHandler()" />
                                            <recess-button v-if="showProviderApprovalButtons(order.status)"
                                                variant="tertiary" :title="BUTTON_TEXT.rejectOrder"
                                                :disabled="disableProviderApprovalButtonsForAdmin"
                                                class="qa-order-details-reject-button"
                                                @click.native.prevent="showRejectOrderModal(order.id)" />
                                        </div>
                                    </div>
                                </div>

                                <!-- Algemeen -->
                                <div class="col-12">
                                    <recess-divider variant="large" show-line />
                                    <h2 class="qa-order-general-details-title">
                                        {{ PROVIDER.Order.OrderDetails.GeneralInfo.Title.Label }}
                                    </h2>
                                    <div class="row">
                                        <div class="col-4">
                                            <div class="qa-order-name">
                                                <h5 class="qa-order-name-label">{{
                                                    PROVIDER.Order.OrderDetails.GeneralInfo.Name.Label }}</h5>
                                                <p class="qa-order-name-value">{{ order.name }}</p>
                                            </div>

                                            <div class="qa-order-product-type">
                                                <h5 class="qa-order-product-type-label">
                                                    {{
                                                        PROVIDER.Order.OrderDetails.GeneralInfo.ProductTypeDisplayValue.Label
                                                    }}
                                                </h5>
                                                <p class="qa-order-product-type-value">{{
                                                    order.purchaseAgreementTypeDisplayValue || '-' }}</p>
                                            </div>

                                            <div class="qa-order-number">
                                                <h5 class="qa-order-number-label">{{
                                                    PROVIDER.Order.OrderDetails.GeneralInfo.Number.Label }}</h5>
                                                <p class="qa-order-number-value">{{ order.orderNumber }}</p>
                                            </div>
                                        </div>

                                        <div class="col-4">
                                            <div class="qa-order-account-name">
                                                <h5 v-if="order.accountReference" class="qa-order-account-name-label">
                                                    {{ PROVIDER.Order.OrderDetails.GeneralInfo.AccountReference.Label }}
                                                </h5>
                                                <p class="qa-order-account-name-value">{{ order.accountName || '-' }}
                                                </p>
                                            </div>

                                            <div class="qa-order-cost-center">
                                                <h5 class="qa-order-cost-center-label">{{
                                                    PROVIDER.Order.OrderDetails.GeneralInfo.CostCenter.Label }}</h5>
                                                <p class="qa-order-cost-center-value">{{ order.costCentre || '-' }}</p>
                                            </div>

                                            <div class="qa-order-date-created">
                                                <h5 class="qa-order-date-created-label">{{
                                                    PROVIDER.Order.OrderDetails.GeneralInfo.DateCreated.Label }}</h5>
                                                <p class="qa-order-date-created-value">{{ order.dateCreated | formatDate
                                                    }}</p>
                                            </div>
                                        </div>

                                        <div class="col-4">
                                            <div>
                                                <h5 class="mb-2 qa-order-document-label">{{
                                                    PROVIDER.Order.OrderDetails.GeneralInfo.PurchaseAgreementOffer.Label
                                                    }}</h5>
                                                <div class="mt-4 qa-order-document-value">
                                                    <div v-if="hasPdf" class="
                                                        d-flex
                                                        align-items-center
                                                        px-0
                                                        mb-4 mb-lg-0
                                                    ">
                                                        <img src="../../assets/images/PDF_icon.svg"
                                                            alt="order-offer-document-logo"
                                                            class="u-flex-reset d-inline-flex mr-3" />

                                                        <span class="u-word-break">
                                                            {{ getDocumentName() }}
                                                        </span>
                                                    </div>
                                                    <div v-else>-</div>
                                                </div>
                                                <!-- download PDF button -->
                                                <recess-button v-if="hasPdf" :title="BUTTON_TEXT.downloadPDF"
                                                    variant="tertiary"
                                                    class="qa-order-document-download-button mr-4 mt-3"
                                                    @click.native.prevent="triggerOrderDocumentDownload()" />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <!-- Project Details -->
                                <div v-if="isIncompanyPurchaseAgreement" class="col-12">
                                    <recess-divider variant="large" show-line />
                                    <h2 class="qa-order-project-details-title">
                                        {{ PROVIDER.Order.OrderDetails.ProjectDetails.Title }}
                                    </h2>

                                    <div class="row">
                                        <div class="col-4">
                                            <div class="qa-order-project-name">
                                                <h5 class="qa-order-project-name-label">{{
                                                    PROVIDER.Order.OrderDetails.ProjectDetails.Name.Label }}</h5>
                                                <p class="qa-order-project-name-value">{{ purchaseAgreement.name }}</p>
                                            </div>

                                            <div class="qa-order-project-product-type">
                                                <h5 class="qa-order-project-product-type-label">
                                                    {{
                                                        PROVIDER.Order.OrderDetails.GeneralInfo.ProductTypeDisplayValue.Label
                                                    }}
                                                </h5>
                                                <p class="qa-order-project-product-type-value">{{
                                                    order.purchaseAgreementTypeDisplayValue || '-' }}</p>
                                            </div>

                                            <div class="qa-order-preferred-startmoment">
                                                <h5 class="qa-order-preferred-startmoment-label">{{
                                                    PROVIDER.Order.OrderDetails.ProjectDetails.PreferredStartmoment.Label
                                                    }}</h5>
                                                <p class="qa-order-preferred-startmoment-value">{{
                                                    formatDate(purchaseAgreement.preferredStartDate) || '-' }}</p>
                                            </div>
                                        </div>

                                        <div class="col-4">
                                            <div class="qa-order-fixed-price">
                                                <h5 class="qa-order-fixed-price-label">
                                                    {{ PROVIDER.Order.OrderDetails.ProjectDetails.FixedPrice.Label }}
                                                </h5>
                                                <p class="qa-order-fixed-price-value">{{ `€
                                                    ${purchaseAgreement.priceWithoutVat}` || '-' }}</p>
                                            </div>

                                            <div class="qa-order-price-person">
                                                <h5 class="qa-order-price-person-label">{{
                                                    PROVIDER.Order.OrderDetails.ProjectDetails.PricePerPerson.Label }}
                                                </h5>
                                                <p class="qa-order-price-person-value">{{ `€
                                                    ${purchaseAgreement.pricePerPersonWithoutVat}` || '-' }}</p>
                                            </div>

                                            <div class="qa-order-list-price">
                                                <h5 class="qa-order-list-price-label">{{
                                                    PROVIDER.Order.OrderDetails.ProjectDetails.ListPrice.Label }}</h5>
                                                <p class="qa-order-list-price-value">{{ `€
                                                    ${purchaseAgreement.listPriceWithoutVat}` || '-' }}</p>
                                            </div>
                                        </div>

                                        <div class="col-4">
                                            <div class="qa-order-number-startmoments">
                                                <h5 class="qa-order-number-startmoments-label">
                                                    {{
                                                        PROVIDER.Order.OrderDetails.ProjectDetails.NumberOfStartmoments.Label
                                                    }}
                                                </h5>
                                                <p class="qa-order-number-startmoments-value">{{
                                                    purchaseAgreement.startMomentsAmount || '-' }}</p>
                                            </div>

                                            <div class="qa-order-min-participants">
                                                <h5 class="qa-order-min-participants-label">{{
                                                    PROVIDER.Order.OrderDetails.ProjectDetails.MinParticipants.Label }}
                                                </h5>
                                                <p class="qa-order-min-participants-value">{{
                                                    purchaseAgreement.minParticipants || '-' }}</p>
                                            </div>

                                            <div class="qa-order-max-participants">
                                                <h5 class="qa-order-max-participants-label">{{
                                                    PROVIDER.Order.OrderDetails.ProjectDetails.MaxParticipants.Label }}
                                                </h5>
                                                <p class="qa-order-max-participants-value">{{
                                                    purchaseAgreement.maxParticipants || '-' }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Prijs details -->
                                <price-details-table-component :price-info="setPriceInfo" class="col-12" />

                                <!-- Facturatie -->
                                <!-- if user is admin, show real billTo address -->
                                <user-info-table-component v-if="shownUserInfoTable" :user="order.billTo"
                                    :invoiced-by="order.invoicedByDisplayValue" :show-properties="{
                                        houseNumberAdditive: false,
                                        birthDate: false,
                                        department: false
                                    }" class="col-12">
                                    <template slot="edit-invoice-on-behalf-of">
                                        <invoice-on-behalf-of-component />
                                    </template>
                                </user-info-table-component>

                                <!-- if user is provider, show default billTo address -->
                                <provider-billing-table-component v-if="showProviderBillingTable" class="col-12"
                                    :order="order">
                                    <template slot="edit-invoice-on-behalf-of">
                                        <invoice-on-behalf-of-component />
                                    </template>
                                </provider-billing-table-component>

                                <div v-if="isDoNotInvoice" class="col-12">
                                    <recess-divider variant="large" show-line />
                                    <div>
                                        <h2 class="qa-do-not-invoice-table">
                                            {{ PROVIDER.Order.OrderDetails.InvoiceDetailsTitleForAdmin }}
                                        </h2>
                                    </div>
                                    <!-- if the order is not to be invoiced, we don't show any billing details -->
                                    <div>
                                        <p v-if="order.invoicedBy" class="qa-billing-order-invoiced-by">
                                            {{ order.invoicedByDisplayValue || '-' }}
                                        </p>

                                        <slot name="edit-invoice-on-behalf-of"></slot>

                                        <template slot="edit-invoice-on-behalf-of">
                                            <invoice-on-behalf-of-component />
                                        </template>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <h5 class="qa-installments-interval-label">{{
                                        PROVIDER.Order.OrderDetails.Installments.Interval }}</h5>
                                    <p class="qa-installments-interval">{{ order.installmentsIntervalDisplayValue }}</p>
                                </div>
                                <div class="col-4">
                                    <h5 class="qa-installments-quantity-label">{{
                                        PROVIDER.Order.OrderDetails.Installments.Quantity }}</h5>
                                    <p class="qa-installments-quantity">{{ order.installmentsQuantity }}</p>
                                </div>

                            </div>
                        </div>
                    </recess-card>
                </div>
            </div>
        </div>

        <accept-purchase-agreement-popup-component :is-modal-visible="isAcceptPurchaseAgreementModalVisible"
            :order-number="orderNumber" :provider-id="providerId" @close="closeAcceptPurchaseAgreementModal"
            @submit="acceptOrder(order.id)" />

        <accept-incompany-purchase-agreement-popup-component
            :is-modal-visible="isAcceptIncompanyPurchaseAgreementModalVisible" :order-number="orderNumber"
            :provider-id="providerId" :account-id="accountId" @close="closeAcceptIncompanyPurchaseAgreementModal"
            @submit="acceptOrder(order.id)" />

        <reject-order-popup-component :is-modal-visible="isRejectOrderModalVisible"
            :is-purchase-agreement-order="isPurchaseAgreement(order?.orderType)" @close="closeRejectOrderModal"
            @submit="rejectOrder(order.id)" />
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { BUTTON_TEXT, API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'
import { getEnumList, getItemById } from '@/../../shared/api/SharedClient'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import acceptRejectOrderMixin from '@/mixins/acceptRejectOrderMixin'
import UserService from '../../../../shared/services/UserService'
import { formatUtcDateTimeToLocalDate } from '@/utils/dateTimeHelper'

const TitleText = () => import('@/components/atoms/TitleText')
const UserInfoTableComponent = () => import('@/components/organisms/Order/UserInfoTableComponent')
const PriceDetailsTableComponent = () => import('@/components/organisms/Order/PriceDetailsTableComponent')
const ProviderBillingTableComponent = () => import('@/components/organisms/Order/ProviderBillingTableComponent')
const InvoiceOnBehalfOfComponent = () => import('@/components/organisms/Order/InvoiceOnBehalfOfComponent')

export default {
    name: 'ProjectOrderDetails',
    components: {
        TitleText,
        UserInfoTableComponent,
        PriceDetailsTableComponent,
        ProviderBillingTableComponent,
        InvoiceOnBehalfOfComponent
    },
    mixins: [acceptRejectOrderMixin],
    data() {
        return {
            userService: new UserService(),
            isAdministrator: false,
            BUTTON_TEXT,
            PROVIDER,
            orderId: this.$route.params.id || null,
            providerId: localStorage.getItem('providerId'),
            orderStatus: [],
            isManualOrApiCourse: true,
            orderNumber: '',
            purchaseAgreement: {}
        }
    },
    computed: {
        ...mapState('orderModule', ['order']),
        getStudentFullName() {
            if (this.order && this.order.customer) {
                return `${this.order.customer.firstName} ${this.order.customer.surname}`
            }
            return ''
        },
        priceDetails() {
            if (!this.order || !this.order.orderLines || this.order.orderLines.length < 1) return null
            return this.order.orderLines
        },
        isOpleidingDirect() {
            return this.order.invoicedBy === PROVIDER.Order.OrderDetails.InvoicedBy.OpleidingDirect
        },
        isThirdParty() {
            return this.order.invoicedBy === PROVIDER.Order.OrderDetails.InvoicedBy.ThirdParty
        },
        isDoNotInvoice() {
            return this.order.invoicedBy === PROVIDER.Order.OrderDetails.InvoicedBy.DoNotInvoice
        },
        isOPLZ() {
            return this.order.invoicedBy === PROVIDER.Order.OrderDetails.InvoicedBy.OPLZ
        },
        isNCOI() {
            return this.order.invoicedBy === PROVIDER.Order.OrderDetails.InvoicedBy.NCOI
        },
        shownUserInfoTable() {
            if (this.isDoNotInvoice) {
                return false
            }
            return (this.isAdministrator || this.isThirdParty) && this.order.billTo
        },
        showProviderBillingTable() {
            if (this.isDoNotInvoice) {
                return false
            }

            return !this.isAdministrator && (!this.order.billTo || this.isOPLZ || this.isNCOI || this.isOpleidingDirect)
        },
        setPriceInfo() {
            return {
                details: this.priceDetails,
                total: this.order?.total,
                isVatExempt: this.order?.isVatExempt
            }
        },
        courseId() {
            return this.order && this.order.productReference && this.order.productReference.id
        },
        hasPdf() {
            return this.purchaseAgreement?.purchaseAgreementAttachments?.length > 0
        },
        isIncompanyPurchaseAgreement() {
            return this.purchaseAgreement.type === 'IncompanyWithoutStartmoments' || this.purchaseAgreement.type === 'IncompanyWithStartmoments'
        },
        accountId() {
            return this.order && this.order.accountReference && this.order.accountReference.id
        }
    },
    watch: {
        courseId: {
            handler() {
                if (this.courseId) {
                    this.getCourse()
                }
            }
        },
        order: {
            handler() {
                if (this.order) this.getPurchaseAgreementDetails()
            }
        }
    },
    async created() {
        this.isAdministrator = await this.userService.isAdministrator()
    },
    mounted() {
        this.$nextTick(() => {
            this.getDataOnPageChange()
            this.getOrderStatus()
            this.$bus.on('update-order-status-transition', this.setOrderStatusTransition)
            this.$bus.on('validate-post-legal-company', this.validatePostLegalCompany)
            this.$bus.on('refresh-orders', this.getOrdersAfterRebuildOrderIndex)
        })
    },
    beforeDestroy() {
        this.resetOrderState()
        this.$bus.off('update-order-status-transition')
        this.$bus.off('validate-post-legal-company')
        this.$bus.off('refresh-orders')
    },
    methods: {
        ...mapActions('orderModule', ['getOrder']),
        ...mapMutations('orderModule', ['resetOrderState']),
        getDataOnPageChange() {
            this.getOrder(this.orderId)
        },
        showOrderStatusDisplayValue(status) {
            return (
                this.orderStatus &&
                this.orderStatus.reduce((acc, currentValue) => {
                    if (currentValue.value === status) {
                        return currentValue.displayText
                    }
                    return acc
                }, [])
            )
        },
        showOrderTypeDisplayValue(type) {
            return this.isPurchaseAgreement(type) ? PROVIDER.Order.OrderList.OrderTypes.PurchaseAgreement : PROVIDER.Order.OrderList.OrderTypes.Course
        },
        async getOrderStatus() {
            try {
                const response = await getEnumList(process.env.VUE_APP_ORDER_API_URL, API_CALL_URL_PATHS.enumerations, 'orderStatus', null, null, false)
                if (!response) return
                this.orderStatus = [...this.orderStatus, ...response]
            } catch (error) {
                console.error('Something went wrong while retrieving order status enum list', error)
            }
        },
        acceptOrderHandler() {
            this.orderNumber = `${this.order.orderNumber}`

            this.isIncompanyPurchaseAgreement ? this.showAcceptIncompanyPurchaseAgreementModal() : this.showAcceptPurchaseAgreementModal()
        },
        async getPurchaseAgreementDetails() {
            const purchaseAgreementDetails = await getItemById(
                process.env.VUE_APP_ORDER_API_URL,
                API_CALL_URL_PATHS.purchaseAgreements,
                this.order?.purchaseAgreementId,
                'PurchaseAgreementAttachments',
                false
            )
            if (!purchaseAgreementDetails) return

            this.purchaseAgreement = purchaseAgreementDetails
        },
        triggerOrderDocumentDownload() {
            this.downloadOfferDocument()
        },
        downloadOfferDocument() {
            const createLink = document.createElement('a')

            createLink.setAttribute('href', this.purchaseAgreement.purchaseAgreementAttachments[0].offerUrl)

            createLink.style.display = 'none'

            document.body.appendChild(createLink)

            createLink.click()

            document.body.removeChild(createLink)
        },
        getDocumentName() {
            const url = this.purchaseAgreement.purchaseAgreementAttachments[0].offerUrl

            // Extract the file name part from the URL
            const fileNamePart = url.split('/').pop()

            // Decode and replace spaces with underscores
            const decodedName = decodeURIComponent(fileNamePart).replace(/ /g, "_")

            // Split by underscores
            const splitFileName = decodedName.split('_')

            // Remove time stamp
            splitFileName.pop()

            // Join final name by underscores
            const cleanFileName = splitFileName.join('_')

            return `${cleanFileName}.pdf`
        },
        formatDate(date) {
            return formatUtcDateTimeToLocalDate(date)
        }
    }
}
</script>
